<template>
  <div>
    <div
      v-loading="loading"
      :element-loading-text="loadingText"
      :style="getStyle"
      v-show="
        !loading &&
        data &&
        data.properties &&
        data.properties.filed_content != 'Hide' &&
        !visibility
      "
    >
      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPos ? 4 : data.description ? 14 : 8">
          <label for="text" style="font-weight: bold">{{ data.label }}</label>
          <span v-if="data.validations.required" style="color: red; font-weight: bold">*</span>
        </el-col>
        <el-col :span="16">
          <div class="d-flex" v-if="allRowsData.length > limit">
            <el-input
              placeholder="Search"
              v-model="searchString"
              size="mini"
              class="mr-1"
              clearable
              @input="updatePagination"
              style="width: 200px"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-button
              type="primary"
              icon="el-icon-arrow-left"
              size="mini"
              @click="pageChange('BACKWARD')"
              :disabled="page <= 1"
            ></el-button>
            <span class="ml-1 mr-1">{{ page }}</span>
            <el-button
              type="primary"
              icon="el-icon-arrow-right"
              size="mini"
              @click="pageChange('FORWARD')"
              :disabled="(allRowsData.length || 0) <= page * limit"
            ></el-button>
            <span class="ml-1">Total {{ allRowsData.length }}</span>
          </div>
        </el-col>
      </el-row>
      <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
      <div
        v-if="data.table_data_view_type == 'table_view'"
        class="table-container"
        v-loading="dataRefresh"
      >
        <template v-if="getGroupingStatus && isView">
          <table class="content-table" style="width: -webkit-fill-available">
            <tr>
              <th
                v-for="(column, index) in  groupFieldsByOrder(data.data_table_columns, {
                        isHeader:true,
                        field:this.getGroupingStatus,
                        isGrouping:true,
                        groupId:'',
                        paymentId:'',
                        considerGroupFieldsForHeader:true
                        
                      })"
                :key="index+'_'+getRandomKey"
                :style="{
                        width: `${column.width}px`,
                        minWidth: `${column.width}px`, // Set min-width dynamically based on column.width
                      }"
              >
                {{ column.label }}
                <span>
                  <el-popover
                    v-if="column.description"
                    placement="top-start"
                    trigger="hover"
                    :content="column.description"
                  >
                    <i class="el-icon-info" slot="reference"></i>
                  </el-popover>
                </span>
                <span
                  v-if="!isView && column.validations.required"
                  style="
                          color: red;
                          font-weight: bold;
                          margin-left: 5px;
                          margin-right: 5px;
                        "
                >*</span>
              </th>
              <th
                v-if="
                        !isView &&
                        !isDetailedView &&
                        data.child_table_type == 'interactive'
                      "
                class="sticky"
              >
                <el-tooltip :content="'Add ' + data.label">
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-plus"
                    class="p-1"
                    :disabled="
                            checkEntityVariable('ADD')
                              ? checkEntityVariable('ADD')
                              : readonly()
                          "
                    @click="addNewRow(-1)"
                  ></el-button>
                </el-tooltip>
              </th>
            </tr>
            <template v-for="(group) in getGroupedData">
              <template v-for="(action,actionIndex) in group.actions">
                <template v-for="(entry, entryIndex) in action.entries">
                  <tr :key="entryIndex+'_'+actionIndex+getRandomKey">
                    <td
                      :key="entryIndex+'_GroupMainKey'+getRandomKey"
                      v-if="actionIndex === 0 && entryIndex === 0 "
                      :rowspan="group.actions.reduce((sum, action) => sum + action.entries.length, 0)"
                    >
                      <component
                        v-bind:is="
                                  entry.groupFields.components
                                      ? entry.groupFields.components.execute
                                      : fieldMap[entry.groupFields.input_type]
                                  "
                        :data="entry.groupFields"
                        :form="entry.form"
                        :fieldsData="fieldsData"
                        :parentFormData="getParentFormData(entry.groupFields)"
                        :is-view="isGetView(entry.groupFields, entry.form)"
                        :dataTableRowIndex="'GROUP_'+entryIndex"
                        :autoFillEntityData="
                                    getAutoFillEntityData(entry.groupFields, entry.form)
                                  "
                        @entityDataUpdated="setDataToEntityVariables"
                        @clearEntityFields="unsetEntityVariables"
                        @UIUpdated="updateParentUI"
                        :readonly="readonly(entryIndex)"
                        :rowsData="rowsData"
                        :checkIsDisabled="checkIsDisabled(entry.groupFields.key, entryIndex)"
                        :dataTableField="data"
                        :filesInfo="filesInfo"
                        :fromChildTable="true"
                        :fromDT="true"
                        :rowIndex="`${entryIndex}_${entry.groupFields.key}`"
                        @refreshTableColumns="refreshTableColumns"
                        :refreshEntityFields="
                                    refreshEntityColumn &&
                                    refreshEntityColumnIndex !== `${entryIndex}_${entry.groupFields.key}`
                                  "
                        :isInDataTable="true"
                        :suggestedData="getSuggestedRowData(entry.form, entryIndex)"
                        @applyFormRules="
                                    rulesEventEmitter(
                                      entry.fields,
                                      entryIndex,
                                      entry.form.keyIndex,
                                      entry.groupFields.key
                                    )
                                  "
                        :isFromChildTable="true"
                        :isApplicationUserSide="isApplicationUserSide"
                        :childEntitySettings="getPaymentRelatedInfo(entry.groupFields,entry.form,[])"
                        :type="type"
                        :category="getCategory"
                        :templateData="getTemplateId(entry.groupFields)"
                        :currentStep="getTemplateId(entry.groupFields)"
                        :currentEntity="currentEntity"
                        :entityDataId="entry.form._id"
                        :entityData="entry.form"
                        :key="`${entryIndex}-${entryIndex}-${entry.groupFields.input_type}-${getRandomKey}`"
                      ></component>
                    </td>

                    <template
                      v-for="(fieldInfo,fieldIndex) in groupFieldsByOrder(entry.fields, {
                            isHeader:false,
                            field:{},
                            isGrouping:true,
                            groupId:group.groupIdFromField,
                            paymentId:entry.form.payId,
                            considerGroupFieldsForHeader:false  
                          })"
                    >
                      <td :key="entryIndex+'_ColKey'+fieldIndex+getRandomKey">
                        <component
                          v-if="!checkhideFields(fieldInfo.key, fieldIndex) "
                          v-bind:is="
                                        fieldInfo.components
                                            ? fieldInfo.components.execute
                                            : fieldMap[fieldInfo.input_type]
                                        "
                          :data="fieldInfo"
                          :form="entry.form"
                          :fieldsData="fieldsData"
                          :parentFormData="getParentFormData(fieldInfo)"
                          :is-view="isGetView(fieldInfo, entry.form)"
                          :dataTableRowIndex="'Field'+fieldIndex"
                          :autoFillEntityData="
                                          getAutoFillEntityData(fieldInfo, entry.form)
                                        "
                          @entityDataUpdated="setDataToEntityVariables"
                          @clearEntityFields="unsetEntityVariables"
                          @UIUpdated="updateParentUI"
                          :readonly="readonly(fieldIndex)"
                          :rowsData="rowsData"
                          :checkIsDisabled="checkIsDisabled(fieldInfo.key, fieldIndex)"
                          :dataTableField="data"
                          :filesInfo="filesInfo"
                          :fromChildTable="true"
                          :fromDT="true"
                          :rowIndex="`${fieldIndex}_${fieldInfo.key}`"
                          @refreshTableColumns="refreshTableColumns"
                          :refreshEntityFields="
                                          refreshEntityColumn &&
                                          refreshEntityColumnIndex !== `${fieldIndex}_${fieldInfo.key}`
                                        "
                          :isInDataTable="true"
                          :suggestedData="getSuggestedRowData(entry.form, fieldIndex)"
                          @applyFormRules="
                                          rulesEventEmitter(
                                            entry.fields,
                                            fieldIndex,
                                            entry.form.keyIndex,
                                            fieldInfo.key
                                          )
                                        "
                          :isFromChildTable="true"
                          :isApplicationUserSide="isApplicationUserSide"
                          :childEntitySettings="getPaymentRelatedInfo(fieldInfo,entry.form,[])"
                          :type="type"
                          :category="getCategory"
                          :templateData="getTemplateId(fieldInfo)"
                          :currentStep="getTemplateId(fieldInfo)"
                          :currentEntity="currentEntity"
                          :entityDataId="entry.form._id"
                          :entityData="entry.form"
                          :key="`${fieldIndex}-${fieldIndex}-${fieldInfo.input_type}-${getRandomKey}`"
                        ></component>
                      </td>
                    </template>

                    <td
                      :key="entryIndex+'_PaymentMainKey'"
                      v-if="entryIndex === 0  "
                      :rowspan="action.entries.length"
                    >
                      <component
                        v-bind:is="
                          entry.payFields.components
                              ? entry.payFields.components.execute
                              : fieldMap[entry.payFields.input_type]
                          "
                        :data="entry.payFields"
                        :form="entry.form"
                        :fieldsData="fieldsData"
                        :parentFormData="getParentFormData(entry.payFields)"
                        :is-view="isGetView(entry.payFields, entry.form)"
                        :dataTableRowIndex="'PAYMENT_'+entryIndex"
                        :autoFillEntityData="
                            getAutoFillEntityData(entry.payFields, entry.form)
                          "
                        @entityDataUpdated="setDataToEntityVariables"
                        @clearEntityFields="unsetEntityVariables"
                        @UIUpdated="updateParentUI"
                        :readonly="readonly(entryIndex)"
                        :rowsData="rowsData"
                        :checkIsDisabled="checkIsDisabled(entry.payFields.key, entryIndex)"
                        :dataTableField="data"
                        :filesInfo="filesInfo"
                        :fromChildTable="true"
                        :fromDT="true"
                        :rowIndex="`${entryIndex}_${entry.payFields.key}`"
                        @refreshTableColumns="refreshTableColumns"
                        :refreshEntityFields="
                            refreshEntityColumn &&
                            refreshEntityColumnIndex !== `${entryIndex}_${entry.payFields.key}`
                          "
                        :isInDataTable="true"
                        :suggestedData="getSuggestedRowData(entry.form, entryIndex)"
                        @applyFormRules="
                            rulesEventEmitter(
                              entry.fields,
                              entryIndex,
                              entry.form.keyIndex,
                              entry.payFields.key
                            )
                          "
                        :isFromChildTable="true"
                        :isApplicationUserSide="isApplicationUserSide"
                        :childEntitySettings="getPaymentRelatedInfo(entry.payFields,entry.form,action.entries)"
                        :type="type"
                        :category="getCategory"
                        :templateData="getTemplateId(entry.payFields)"
                        :currentStep="getTemplateId(entry.payFields)"
                        :currentEntity="currentEntity"
                        :entityDataId="entry.form._id"
                        :entityData="entry.form"
                        :key="`${entryIndex}-${entryIndex}-${entry.payFields.input_type}_${getRandomKey}`"
                      ></component>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </table>
        </template>
        <template v-else>
          <table class="content-table" style="width: -webkit-fill-available">
            <tr>
              <th
                v-for="(column, index) in removeHideFields(
                    data.data_table_columns
                  )"
                :key="index+'_'+getRandomKey"
                :style="{
                    width: `${column.width}px`,
                    minWidth: `${column.width}px`, // Set min-width dynamically based on column.width
                  }"
              >
                {{ column.label }}
                <span>
                  <el-popover
                    v-if="column.description"
                    placement="top-start"
                    trigger="hover"
                    :content="column.description"
                  >
                    <i class="el-icon-info" slot="reference"></i>
                  </el-popover>
                </span>
                <span
                  v-if="!isView && column.validations.required"
                  style="
                      color: red;
                      font-weight: bold;
                      margin-left: 5px;
                      margin-right: 5px;
                    "
                >*</span>
              </th>
              <th
                v-if="
                    !isView &&
                    !isDetailedView &&
                    data.child_table_type == 'interactive'
                  "
                class="sticky"
              >
                <el-tooltip :content="'Add ' + data.label">
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-plus"
                    class="p-1"
                    :disabled="
                        checkEntityVariable('ADD')
                          ? checkEntityVariable('ADD')
                          : readonly()
                      "
                    @click="addNewRow(-1)"
                  ></el-button>
                </el-tooltip>
              </th>
            </tr>

            <tr
              v-for="(rowData, rowIndex) in getRowsData"
              :key="
              rowIndex +
              'data_table' +
              (rowData && rowData.form && rowData.form.keyIndex
                ? rowData.form.keyIndex
                : '')
            "
              ref="newlyAddedRow"
            >
              <td
                v-for="(column, colIndex) in removeHideFields(rowData.fields)"
                :key="colIndex + '_main_fields'"
              >
                <component
                  v-if="!checkhideFields(column.key, rowIndex) "
                  v-bind:is="
                  column.components
                    ? column.components.execute
                    : fieldMap[column.input_type]
                "
                  :data="column"
                  :form="rowData.form"
                  :fieldsData="fieldsData"
                  :parentFormData="getParentFormData(column)"
                  :is-view="isGetView(column, rowData.form)"
                  :dataTableRowIndex="rowIndex"
                  :autoFillEntityData="
                  getAutoFillEntityData(column, rowData.form)
                "
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @UIUpdated="updateParentUI"
                  :readonly="readonly(rowIndex)"
                  :rowsData="rowsData"
                  :checkIsDisabled="checkIsDisabled(column.key, rowIndex)"
                  :dataTableField="data"
                  :filesInfo="filesInfo"
                  :fromChildTable="true"
                  :fromDT="true"
                  :rowIndex="`${rowIndex}_${column.key}`"
                  @refreshTableColumns="refreshTableColumns"
                  :refreshEntityFields="
                  refreshEntityColumn &&
                  refreshEntityColumnIndex !== `${rowIndex}_${column.key}`
                "
                  :isInDataTable="true"
                  :suggestedData="getSuggestedRowData(rowData.form, rowIndex)"
                  @applyFormRules="
                  rulesEventEmitter(
                    rowData.fields,
                    rowIndex,
                    rowData.form.keyIndex,
                    column.key
                  )
                "
                  :isFromChildTable="true"
                  :isApplicationUserSide="isApplicationUserSide"
                  :childEntitySettings="getPaymentRelatedInfo(column,rowData.form,[])"
                  :type="type"
                  :category="getCategory"
                  :templateData="getTemplateId(column)"
                  :currentStep="getTemplateId(column)"
                  :currentEntity="currentEntity"
                  :entityDataId="rowData.form._id"
                  :entityData="rowData.form"
                  :key="`${rowIndex}-${colIndex}-${column.input_type}`"
                ></component>
                <!-- </span>
                <span v-else>
                   :is-view="!isExecuteCopy"
                  :hasLabel="hasLabel"
                  :label="getFieldLabelFromRules(field)"
                  :checkIsDisabled="checkIsDisabled(field)" 
                  @applyFormRules="rulesEventEmitter"
                
                   Payment execute 2 
                  <PaymentExecute
                    :data="column"
                    :form="rowData.form"
                    :entityDataId="rowData.form._id"
                    :entityData="rowData.form"
                    :currentEntity="currentEntity"
                    :currentStep="getTemplateId(column)"
                    :fieldsData="fieldsData"
                    :templateData="getTemplateId(column)"
                    :isApplicationUserSide="isApplicationUserSide"
                    :type="type"
                    :category="getCategory"
                    :isFromChildTable="true"
                    :childEntitySettings="getPaymentRelatedInfo(column,rowData.form,[])"
                  >
                  </PaymentExecute>
                </span>-->
              </td>
              <template>
                <td
                  v-show="tempHideFields"
                  v-for="(column, colIndex) in hiddenFields(rowData.fields)"
                  :key="colIndex + '_hidden_fields'"
                >
                  <span v-if="column.input_type!='PAY_BUTTON'">
                    <component
                      v-if="!checkhideFields(column.key, rowIndex)"
                      v-bind:is="
                    column.components
                      ? column.components.execute
                      : fieldMap[column.input_type]
                  "
                      :data="column"
                      :form="rowData.form"
                      :fieldsData="fieldsData"
                      :is-view="isGetView(column, rowData.form)"
                      :parentFormData="getParentFormData(column)"
                      :dataTableRowIndex="rowIndex"
                      :autoFillEntityData="
                    getAutoFillEntityData(column, rowData.form)
                  "
                      @entityDataUpdated="setDataToEntityVariables"
                      @clearEntityFields="unsetEntityVariables"
                      @UIUpdated="updateParentUI"
                      :readonly="readonly(rowIndex)"
                      :rowsData="rowsData"
                      :checkIsDisabled="checkIsDisabled(column.key, rowIndex)"
                      :dataTableField="data"
                      :fromChildTable="true"
                      @refreshTableColumns="refreshTableColumns"
                      :refreshEntityFields="
                    refreshEntityColumn &&
                    refreshEntityColumnIndex !== `${rowIndex}_${column.key}`
                  "
                      :isInDataTable="true"
                      :suggestedData="getSuggestedRowData(rowData.form, rowIndex)"
                      @applyFormRules="
                    rulesEventEmitter(
                      rowData.fields,
                      rowIndex,
                      rowData.form.keyIndex,
                      column.key
                    )
                  "
                      :filesInfo="filesInfo"
                      :fromDT="true"
                      :rowIndex="`${rowIndex}_${column.key}`"
                      :isFromChildTable="true"
                      :isApplicationUserSide="isApplicationUserSide"
                    ></component>
                  </span>
                  <span v-else>execute Pay button</span>
                </td>
              </template>
              <th
                v-if="
                !isView &&
                !isDetailedView &&
                data.child_table_type == 'interactive'
              "
                class="sticky"
              >
                <div class="d-flex">
                  <el-tooltip :content="'Remove ' + data.label">
                    <el-button
                      size="mini"
                      icon="el-icon-minus"
                      class="p-1"
                      @click="removeRow(rowIndex)"
                      :disabled="
                      checkEntityVariable('EDIT')
                        ? checkEntityVariable('EDIT')
                        : readonly()
                    "
                    ></el-button>
                  </el-tooltip>
                  <el-button
                    v-if="data.is_entity_variable"
                    size="mini"
                    icon="el-icon-plus"
                    @click="splitRow(rowIndex)"
                    :disabled="
                    checkEntityVariable('EDIT')
                      ? checkEntityVariable('EDIT')
                      : readonly()
                  "
                  ></el-button>
                </div>
              </th>
            </tr>
          </table>
        </template>
      </div>
      <div v-else v-loading="dataRefresh" class="data-table-view">
        <div v-if="getRowsData.length == 0" class="nodata-collapsable">
          <p>Click to add items</p>
          <el-button
            size="mini"
            icon="el-icon-plus"
            :disabled="
              checkEntityVariable('ADD')
                ? checkEntityVariable('ADD')
                : readonly()
            "
            @click="addNewRow(-1)"
          ></el-button>
        </div>
        <el-collapse v-else class="content-table">
          <el-collapse-item
            v-for="(rowData, rowIndex) in getRowsData"
            :key="
              rowIndex +
              'data_table' +
              (rowData && rowData.form && rowData.form.keyIndex
                ? rowData.form.keyIndex
                : '')
            "
            ref="newlyAddedRow"
          >
            <template
              slot="title"
              v-if="
                !isView &&
                !isDetailedView &&
                data.child_table_type == 'interactive'
              "
            >
              <el-button
                size="mini"
                icon="el-icon-plus"
                :disabled="
                  checkEntityVariable('ADD')
                    ? checkEntityVariable('ADD')
                    : readonly()
                "
                @click="addNewRow(-1)"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-minus"
                class="p-1"
                @click="removeRow(rowIndex)"
                :disabled="
                  checkEntityVariable('EDIT')
                    ? checkEntityVariable('EDIT')
                    : readonly()
                "
              ></el-button>
              <el-button
                v-if="data.is_entity_variable"
                size="mini"
                icon="el-icon-plus"
                @click="splitRow(rowIndex)"
                :disabled="
                  checkEntityVariable('EDIT')
                    ? checkEntityVariable('EDIT')
                    : readonly()
                "
              ></el-button>
            </template>
            <div style="height: 500px; position: relative">
              <div
                v-for="(column, colIndex) in removeHideFields(rowData.fields)"
                :key="colIndex + '_main_fields'"
                :style="getAccordionStyle(column)"
              >
                <p>{{ column.label }}</p>
                <component
                  v-if="!checkhideFields(column.key, rowIndex)"
                  v-bind:is="
                    column.components
                      ? column.components.execute
                      : fieldMap[column.input_type]
                  "
                  :data="column"
                  :form="rowData.form"
                  :fieldsData="fieldsData"
                  :parentFormData="getParentFormData(column)"
                  :is-view="isGetView(column, rowData.form)"
                  :dataTableRowIndex="rowIndex"
                  :autoFillEntityData="
                    getAutoFillEntityData(column, rowData.form)
                  "
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @UIUpdated="updateParentUI"
                  :readonly="readonly(rowIndex)"
                  :rowsData="rowsData"
                  :checkIsDisabled="checkIsDisabled(column.key, rowIndex)"
                  :dataTableField="data"
                  :filesInfo="filesInfo"
                  :fromChildTable="true"
                  :fromDT="true"
                  :rowIndex="`${rowIndex}_${column.key}`"
                  @refreshTableColumns="refreshTableColumns"
                  :refreshEntityFields="
                    refreshEntityColumn &&
                    refreshEntityColumnIndex !== `${rowIndex}_${column.key}`
                  "
                  :isInDataTable="false"
                  :suggestedData="getSuggestedRowData(rowData.form, rowIndex)"
                  @applyFormRules="
                    rulesEventEmitter(
                      rowData.fields,
                      rowIndex,
                      rowData.form.keyIndex,
                      column.key
                    )
                  "
                ></component>
              </div>
            </div>
            <template>
              <td
                v-show="tempHideFields"
                v-for="(column, colIndex) in hiddenFields(rowData.fields)"
                :key="colIndex + '_hidden_fields'"
              >
                <component
                  v-if="!checkhideFields(column.key, rowIndex)"
                  v-bind:is="
                    column.components
                      ? column.components.execute
                      : fieldMap[column.input_type]
                  "
                  :data="column"
                  :form="rowData.form"
                  :fieldsData="fieldsData"
                  :is-view="isGetView(column, rowData.form)"
                  :parentFormData="getParentFormData(column)"
                  :dataTableRowIndex="rowIndex"
                  :autoFillEntityData="
                    getAutoFillEntityData(column, rowData.form)
                  "
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @UIUpdated="updateParentUI"
                  :readonly="readonly(rowIndex)"
                  :rowsData="rowsData"
                  :checkIsDisabled="checkIsDisabled(column.key, rowIndex)"
                  :dataTableField="data"
                  :fromChildTable="true"
                  @refreshTableColumns="refreshTableColumns"
                  :refreshEntityFields="
                    refreshEntityColumn &&
                    refreshEntityColumnIndex !== `${rowIndex}_${column.key}`
                  "
                  :isInDataTable="true"
                  :suggestedData="getSuggestedRowData(rowData.form, rowIndex)"
                  @applyFormRules="
                    rulesEventEmitter(
                      rowData.fields,
                      rowIndex,
                      rowData.form.keyIndex,
                      column.key
                    )
                  "
                  :filesInfo="filesInfo"
                  :fromDT="true"
                  :rowIndex="`${rowIndex}_${column.key}`"
                ></component>
              </td>
            </template>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div v-if="loading" :style="'text-align: center;'">
      <i class="el-icon-loading"></i>
      <span>{{ loadingText }}</span>
    </div>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { dataTableAllowedExcecuteFields } from "./index";
import { dataTableAllowedViewFields } from "./../formComponentsView/index";
import { mapGetters } from "vuex";
import { bus } from "../../../main";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import moment from "moment-timezone";
import { postAPICall } from "@/helpers/httpHelper";
import { fetchEntityById } from "@/repo/entityRepo";

export default {
  name: "EntityTableExecute",
  props: [
    "visibility",
    "field",
    "data",
    "isActive",
    "fieldsData",
    "form",
    "isView",
    "isDataTableField",
    "entityDataList",
    "parentField",
    "parentFormData",
    "entityDataId",
    "entityDataExists",
    "savingData",
    "hiddenColumns",
    "checkAllFieldsDisabled",
    // "entityTableDetails",
    "filesInfo",
    "refreshEntityFields",
    "fromEntityViews",
    "suggestedData",

    "isApplicationUserSide",
    "templateData",
    //"currentEntity",
    "currentStep",
    "entityData",
    "allEntitiesData",
    "category",
    "type"
  ],
  mixins: [TemplateBuilderHelper, CustomDashboardConfig, userPermissionsHelper],
  components: {
    ...dataTableAllowedExcecuteFields,
    ...dataTableAllowedViewFields,
    PaymentExecute: () => import("./PaymentExecute")
  },
  data() {
    return {
      showLabel: false,
      rowsData: [],
      childFieldsData: [],
      aggregateFunctionsBasedOnTable: [],
      rowLoading: false,
      parent: null,
      fieldRules: [],
      showTemplateFields: [],
      hideFields: [],
      disabledFields: [],
      auto_fill_field: null,
      auto_fill_data: null,
      weekDays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      previousFormData: null,
      previousAutoFillData: {},
      previousDefaultData: {},
      filters: [],
      loading: false,
      loadingText: "Auto filling data",
      autoFillEntityData: null,
      entityDataMapping: {},
      tempHideFields: false,
      dataRefresh: true,
      searchString: "",
      limit: 50,
      page: 1,
      searchWarning: false,
      entityVariableForm: {},
      outsideFields: [],
      auto_fill_fields: [],
      new_added_row: [],
      entityDatas: {},
      fieldMap: {
        DATE: "DateExecute",
        FILE: "FileExecute",
        MULTI_LINE_TEXT: "MultiLineTextExecute",
        SINGLE_LINE_TEXT: "SingleLineTextExecute",
        MULTI_SELECT: "MultiSelectExecute",
        NUMBER: "NumberExecute",
        SELECT: "SelectExecute",
        LIST: "ListExecute",
        YES_OR_NO: "YesOrNoExecute",
        HEADING: "HeadingExecute",
        CHECKBOX: "CheckBoxExecute",
        SIGNATURE: "ESignatureExecute",
        GLOBAL_VAIRLABE: "GlobalVariableExecute",
        ENTITY_VARIABLE: "EntityVariableExecute",
        PARAGRAPH: "ParagraphExecute",
        SINGLE_LINE_CONTENT: "SingleLineContentExecute",
        AUTHORIZED_SIGNATURE: "AuthorizedSignatureExecute",
        IMAGE: "ImageExecute",
        FORMULA: "FormulaExecute",
        ACTION_BUTTON: "ActionButtonExecute",
        HTML: "HtmlExecute",
        ENTITY: "EntityExecute",
        CURRENCY: "CurrencyExecute",
        PHONE_COUNTRY_CODE: "PhoneCountryCodeExecute",
        DATE_TIME: "DateTimeExecute",
        DATE_TIME_RANGE: "DateTimeRangeExecute",
        DATE_RANGE: "DateRangeExecute",
        TIME: "TimeExecute",
        RADIO: "RadioExecute",
        WEEKDAYS: "WeekDaysExecute",
        TIME_RANGE: "TimeRangeExecute",
        CHECKBOX_GROUP: "CheckBoxGroupExecute",
        AGGREGATE_FUNCTION: "AggregateFunctionExecute",
        STAR_RATING: "StarRatingExecute",
        DATA_TABLE: "DataTableExecute",
        RADIO_BUTTON_GROUP: "RadioButtonGroupExecute",
        AUTO_INCREMENT_NUMBER: "AutoIncrementExecute",
        PAY_BUTTON: "PaymentExecute",
        CONCATENATE: "ConcatenateExecute",
        RANDOM_TEXT: "RandomTextExecute",
        LOCATION: "LocationExecute"
      },
      refreshEntityColumn: false,
      refreshEntityColumnIndex: "",
      fetchingEntityData: false,
      currentEntity: {},
      entityTableDetails: null
    };
  },

  async mounted() {
    if (this.data.child_table_type == "interactive") {
      if (
        this.data.child_table_auto_fill ||
        (this.data.data_table_field_auto_fill &&
          this.data.selected_auto_fill_entity !== "" &&
          this.data.selected_auto_fill_entity == this.data.parent_entityId)
      ) {
        (this.data?.filters || []).map(fil => {
          this.previousAutoFillData[fil.value_field] = this.form[
            fil.value_field
          ];
        });
        await this.autoFillFilteredData();
      } else if (this.form[this.data.key] && this.form[this.data.key].length) {
        await this.fetchEntityDataOfTable();
      }
      if (
        this.data.default_rows &&
        (!this.form[this.data.key] || !this.form[this.data.key].length)
      ) {
        for (let i = 0; i < this.data.default_rows; i++) {
          this.addNewRow(-1);
        }
      }
    } else if (this.data.child_table_type == "view") {
      await this.autoFillFilteredData();
    }
    this.data.showLabel = false;
    this.childFieldsData = this.data.data_table_columns;
    this.getEntityReadOnlyFieldsData();
    this.aggregateFunctionsBasedOnTable = this.fieldsData.filter(field => {
      if (field.fields_type == "TABLE_FIELDS") {
        return true;
      }
    });
    this.currentEntity = await fetchEntityById(this.data.entity_id);
    if (this.form[this.data.key]) {
      await this.fetchEntityFieldsData();
      this.form[this.data.key].forEach(dt => {
        let fields = [
          ...this.data.data_table_columns.map(e => {
            if (e.styles) {
              e.styles.labelStyle = "hide";
            }
            if (e.properties) {
              e.properties.hideLabel = true;
            }

            return e;
          })
        ];
        let e = dt ? this.mapDefaultValues(fields, dt, false, this.form) : {};
        let element = {
          form: e,
          fields
        };
        if (
          this.rowsData.findIndex(
            d =>
              d?.form?.keyIndex == e.keyIndex &&
              !d?.form?.newlyAddedData &&
              !e.newlyAddedData
          ) == -1
        ) {
          this.rowsData.push(element);
        }
      });
    } else {
      this.dataRefresh = false;
    }
    // this.setNewRowElements();
    this.data.styles.labelStyle;
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.is_entity_variable) {
      this.parent = this.fieldsData.find(
        e => e.key == this.data.relationship_key
      );
    }
    if (this.data?.rules) {
      this.fieldRules = this.data.rules;
    }
    if (
      this.data?.data_table_columns &&
      !this.data.is_entity_variable &&
      !this.entityDataId &&
      !this.$route.query.newData
    ) {
      this.auto_fill_field = this.data.data_table_columns.find(
        e => e.data_table_auto_fill
      );
      if (this.auto_fill_field) {
        this.filters = this.mapFilters(this.auto_fill_field.filters);
        this.fetchEntityFieldData();
      }
      if (this.data.child_table_auto_fill) {
        this.autoFillFilteredData();
      } else {
        this.auto_fill_fields = this.data.data_table_columns.filter(
          e => e.data_table_field_auto_fill
        );
        if (this.auto_fill_fields && this.auto_fill_fields.length) {
          let allFilterEntityIds = [];
          this.auto_fill_fields.map(e => {
            if (e.data_table_filters && e.data_table_filters.length) {
              e.data_table_filters.map(fil => {
                if (
                  fil.type == "ENTITY" &&
                  !allFilterEntityIds.includes(fil.entity_id)
                ) {
                  allFilterEntityIds.push(fil.entity_id);
                }
              });
            }
          });
          await Promise.all(
            allFilterEntityIds.map(async e_id => {
              let params = {
                entity_id: e_id,
                template_fields_data: [],
                filters: [],
                relationship: null,
                limit: 500,
                page: 1
              };
              const response = await postAPICall(
                "POST",
                "/entities-data/entity/data",
                params
              );
              if (response.data) {
                this.entityDatas[e_id] = response.data;
              }
            })
          );
          this.autoFillPreferredFields();
        }
      }
    }
    if (
      this.entityVariableTableColumns &&
      this.entityVariableTableColumns.length
    ) {
      this.outsideFields = Array.from(
        new Set(
          this.entityVariableTableColumns.flatMap(e => e.relationship_key)
        )
      );
    }
    bus.$on(
      "entityDataUpdated",
      (selectedData, data, label, changed, dataTableRowIndex) => {
        // this.setNewRowElements();
        this.setDataToEntityVariables(
          selectedData,
          data,
          label,
          changed,
          dataTableRowIndex
        );
      }
    );
    bus.$on("out-side-entity-update", (data, parent, label, changed) => {
      if (
        this.outsideFields.indexOf(parent.key) !== -1 &&
        this.rowsData &&
        this.rowsData.length
      ) {
        this.applyOutSideEntityvariables(data, parent, label, changed);
      }
    });
    this.$emit("setDefultValuesForIndividualFields");
  },

  updated: function() {
    this.$nextTick(() => {
      this.updateParentUI();
    });
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("entitiesData", ["getEntitiesDataForDataTable"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    getCategory() {
      return this.category;
    },
    getRandomKey() {
      return Date.now();
    },
    getRowSpan() {
      return (group, action, index) => {
        return index == 0
          ? group.actions.reduce(
              (sum, action) => sum + action.entries.length,
              0
            )
          : action.entries.length;
      };
    },
    getFilterDataObject() {
      return (groupId, payId, entries) => {
        let payFieldKey = payId.split("#")[0];
        const groupFields = this.data.data_table_columns.find(
          field => field.key === groupId
        );
        const payFields = this.data.data_table_columns.find(
          field => field.key === payFieldKey
        );
        return entries.map(entry => {
          return {
            fields: this.data.data_table_columns,
            form: { ...entry, groupId, payId: payFieldKey, payId_1: payId },
            groupFields, //multiple Fields we need to convert it as array
            payFields
          };
        });
      };
    },
    getGroupingStatus() {
      let groupField = this.data.data_table_columns.find(field => {
        return (
          field.input_type === "PAY_BUTTON" && field.validations.isChildTable
        );
      });
      return groupField;
    },
    getStyle() {
      return this.getElementStyle;
    },
    getBrandingStyles() {
      let style = "";
      if (this.getCompanyDetails?.button?.background) {
        style += "background:" + this.getCompanyDetails.button.background + ";";
      }
      if (this.getCompanyDetails?.button?.button_type === "RECTANGLE") {
        style += "border-radius: 5px;";
      } else {
        style += "border-radius: 50px;";
      }
      return style;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
    getRowsData() {
      // if(this.data.data_table_columns)
      return this.applyPagination(
        this.filterDataByProperties(
          this.rowsData.filter(e => !e.form || !e.form.newlyAddedData) || [],
          this.searchString,
          this.removeHideFields(this.data.data_table_columns).flatMap(
            e => e.key
          )
        )
      );
    },
    allRowsData() {
      return this.filterDataByProperties(
        this.rowsData.filter(e => !e.form || !e.form.newlyAddedData) || [],
        this.searchString,
        this.removeHideFields(this.data.data_table_columns).flatMap(e => e.key)
      );
    },
    isDetailedView() {
      return (
        this.data.is_entity_variable && this.parent && this.parent.detailed_view
      );
    },
    entityVariableTableColumns() {
      const entityVariablesExisted = this.data.data_table_columns.filter(e => {
        if (e.input_type == "ENTITY_VARIABLE" && this.isEntityFromOutside(e)) {
          e.outside_entity_field = this.isEntityFromOutside(e);
          return true;
        }
        return false;
      });
      return entityVariablesExisted;
    },

    getPaymentRelatedInfo() {
      return (field, data, entries) => {
        if (field.input_type != "PAY_BUTTON") {
          return {
            amount: 0,
            currency: "",
            referenceVariable: field.key,
            advancedKey: field.key,
            payButtonSettings: {},
            dataSet: [],
            entityId: this.data.entity_id
          };
        } else {
          let fieldKey = field.key.split("@");
          let paymentVariableKey = `${fieldKey[0]}@${field.validations.entityVariable.key}`;
          let dataSet = [],
            amount = 0,
            transactions = [];
          if (this.isView) {
            dataSet = entries.map(entry => {
              if (entry[`${field.key}_history`]) {
                // transactions.push(...entry[`${field.key}_history`])
                transactions = [
                  ...transactions,
                  ...entry[`${field.key}_history`]
                ];
              }
              return {
                amount: entry[paymentVariableKey],
                dataId: entry._id,
                templateId: fieldKey[0]
              };
            });
            amount = entries.reduce(
              (sum, entry) => sum + entry[paymentVariableKey],
              0
            );
          } else {
            if (data[`${field.key}_history`]) {
              // transactions.push(...entry[`${field.key}_history`])
              transactions = [...data[`${field.key}_history`]];
            }

            dataSet = [
              {
                amount: data[paymentVariableKey],
                dataId: data._id,
                templateId: fieldKey[0]
              }
            ];
            amount = data[paymentVariableKey];
          }
          return {
            templateId: fieldKey[0],
            amount: amount,
            currency: field.validations.currency,
            referenceVariable: fieldKey[1],
            advancedKey: field.key,
            payButtonSettings: field.validations,
            dataSet: dataSet,
            transactions,
            entityId: this.data.entity_id
          };
        }
      };
    },
    getGroupedData() {
      let data = this.getRowsData;
      let field = this.getGroupingStatus;
      const result = {};
      const templateId = field.key.split("@")[0];
      const payActionFromField = field.key;
      const groupIdFromField = `${templateId}@${field.validations.childGroupField}`;
      data.forEach(entry => {
        const groupFields = entry.fields.find(
          field => field.key === groupIdFromField
        );
        const payFields = entry.fields.find(
          field => field.key === payActionFromField
        );
        // const groupId = entry[groupIdFromField];
        const payAction = entry.form[payActionFromField];
        const secondGroupField = `${payActionFromField}#${payAction}`;

        if (!result[entry.form[groupIdFromField]]) {
          let groupColumn = {};
          /*** Need to Set Group fields Values Here  */
          groupColumn[groupIdFromField] = entry.form[groupIdFromField];
          result[entry.form[groupIdFromField]] = {
            groupIdFromField,
            templateId: templateId,
            ...groupColumn,
            actions: {}
          };
        }

        if (!result[entry.form[groupIdFromField]].actions[secondGroupField]) {
          result[entry.form[groupIdFromField]].actions[secondGroupField] = [];
        }
        result[entry.form[groupIdFromField]].actions[secondGroupField].push({
          keyIndex: entry.form["keyIndex"],
          _id: entry.form["_id"],
          ...entry.form,
          fields: entry.fields,
          form: {
            ...entry.form,
            groupId: groupIdFromField,
            payId: payActionFromField,
            payId_1: secondGroupField,
            keyIndex: entry.form["keyIndex"],
            _id: entry.form["_id"]
          },
          groupFields, //multiple Fields we need to convert it as array
          payFields
        });
      });
      // Transform to the desired JSON format
      let formattedData = Object.keys(result).map(groupIdFromField => {
        const group = result[groupIdFromField];
        return {
          groupIdFromField: group.groupIdFromField,
          groupName: group.groupName,
          companyId: group.companyId,
          companyName: group.companyName,
          actions: Object.keys(group.actions).map(payAction => ({
            payAction,
            entries: group.actions[payAction]
          }))
        };
      });
      return formattedData;
    },
    getTemplateId() {
      return field => {
        let templateInfo = field.key.split("@");
        return {
          _id: templateInfo[0],
          template_id: templateInfo[0]
        };
      };
    }
  },
  methods: {
    getAccordionStyle(field) {
      return `position:absolute;left:${field.x}px;top:${field.y}px;height:${field.height}px;width:${field.width}px;`;
    },
    refreshTableColumns(data) {
      this.refreshEntityColumn = false;
      setTimeout(() => {
        this.refreshEntityColumnIndex = data.rowIndex;
        this.refreshEntityColumn = true;
      });
    },
    async fetchEntityDataOfTable() {
      let exitedData = (this.form[this.data.key] || []).filter(
        e => typeof e == "object" && !e._id
      );
      if (exitedData.length == this.form[this.data.key].length) {
        return;
      }
      let params = {
        entity_id: this.data.entity_id,
        template_fields_data: [],
        filters: [],
        relationship: null,
        limit: null,
        page: 1,
        entity_data_ids: Array.from(new Set(this.form[this.data.key])) || []
      };
      this.form[this.data.key] = [];
      const response = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      if (response?.data) {
        let rows = [];
        response.data.map(eData => {
          let form = {};
          form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
          form["_id"] = eData._id;
          this.data.data_table_columns.map(f => {
            form[f.key] =
              eData.entityData[f.key.split("@")[0]][f.key.split("@")[1]];
            if (
              Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                `${f.key.split("@")[1]}/name`
              )
            ) {
              form[`${f.key}/name`] =
                eData.entityData[f.key.split("@")[0]][
                  `${f.key.split("@")[1]}/name`
                ];
            }
            if (
              Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                `${f.key.split("@")[1]}_code`
              )
            ) {
              form[`${f.key}_code`] =
                eData.entityData[f.key.split("@")[0]][
                  `${f.key.split("@")[1]}_code`
                ];
            }
          });
          let row = {
            fields: this.data.data_table_columns,
            form: form
          };
          this.rowsData.push(row);
          rows.push(row);
        });
        this.$set(this.form, this.data.key, rows);
      }
    },
    applyOutSideEntityvariables(data, parent, label, changed) {
      this.rowsData = this.rowsData.map(row => {
        let form = row.form || {};
        row.form = this.applyEntityVariableData(
          this.entityVariableTableColumns,
          form,
          data,
          parent,
          changed
        );
        return row;
      });
    },
    getSuggestedRowData(rowData, index) {
      return this.suggestedData?.[this.data.key]?.[index] || rowData;
    },
    applyEntityVariableData(fields, form, data, parent, changed) {
      fields.forEach(field => {
        if (
          field.relationship_key == parent.key &&
          (field.inputType == "ENTITY" ||
            field.input_type == "ENTITY_VARIABLE") &&
          (field.field_assignable == "read_only" || !form[field.key] || changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                let existedIds = (form[field.key] || []).flatMap(
                  dt => dt.parentDataId
                );
                (data || []).forEach(e => {
                  if (
                    e?.entityData?.[templateId]?.[key] &&
                    existedIds.indexOf(e._id) == -1
                  ) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach(dt => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName
                            }
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (existedIds.length) {
                  dataValues = [...dataValues, ...(form[field.key] || [])];
                }
                if (field.inputType == "DATA_TABLE") {
                  this.$set(form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(form, field.key, min);
                  } else {
                    this.$set(form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];

                if (field.inputType == "ENTITY") {
                  valueName = data.entityData[templateId][key + "/name"];
                  if (typeof value == "object" && value.length) {
                    this.$set(form, field.key + "/default", value);
                  } else {
                    this.$set(form, field.key + "/default", [value]);
                  }
                }
              }

              if (form[field.key]) {
                form[field.key] = value;
                if (valueName) {
                  form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(form, field.key, value);
                if (valueName) {
                  this.$set(form, field.key + "/name", valueName);
                }
              }
            }
          }
        }
      });
      return form;
    },
    filterDataByProperties(data, searchString, properties) {
      if (!searchString || !properties.length || searchString.length < 2) {
        return data;
      }
      const searchLowerCase = searchString.toLowerCase();

      return data.filter(item => {
        return properties.some(property => {
          const propertyValue =
            item && item.form && item.form[property]
              ? item.form[property + "/name"]
                ? item.form[property + "/name"].toString().toLowerCase()
                : item.form[property].toString().toLowerCase()
              : "";
          return propertyValue.includes(searchLowerCase);
        });
      });
    },
    updatePagination() {
      if (
        this.searchString &&
        this.searchString.length == 1 &&
        !this.searchWarning
      ) {
        this.$message({
          message: "Please enter more than 2 letters to search",
          type: "warning"
        });
        this.searchWarning = true;
      }
      if (!this.searchString || this.searchString.length < 3) {
        this.page = 1;
      }
    },
    pageChange(change) {
      if (change == "FORWARD") {
        this.page++;
      } else {
        this.page--;
      }
    },
    applyPagination(rowsData) {
      return rowsData.slice(
        (this.page - 1) * this.limit,
        this.limit + (this.page - 1) * this.limit
      );
    },
    scrollToNewRow(index) {
      if ("scrollBehavior" in document.documentElement.style) {
        const row = this.$refs.newlyAddedRow[index];
        if (row) {
          row.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "start"
          });
        }
      }
    },
    async getEntityReadOnlyFieldsData() {
      if (this.data.is_entity_variable) {
        let entityFields = (
          this.removeHideFields(this.childFieldsData) || []
        ).filter(
          e => e.inputType == "ENTITY" && e.field_assignable == "read_only"
        );
        await Promise.all(
          entityFields.map(async el => {
            let params = {
              entity_id: el.entity_id,
              template_fields_data: [],
              filters: [],
              relationship: null,
              limit: 500,
              page: 1
            };
            const response = await postAPICall(
              "POST",
              "/entities-data/entity/data",
              params
            );
            if (response?.data) {
              this.entityDataMapping[el.entity_id] = {
                ...response,
                ...{ update: true }
              };
            }
            return el;
          })
        );
      }
    },
    getAutoFillEntityData(field, form) {
      if (
        this.auto_fill_field?.key == field.key &&
        form[field.key + "@autofill"]
      ) {
        return this.autoFillEntityData;
      }
      if (
        (this.checkFieldIsReadOnly(field) ||
          this.checkIsIndependentField(field)) &&
        this.entityDataMapping &&
        this.entityDataMapping[field.entity_id]
      ) {
        return this.entityDataMapping[field.entity_id];
      }
      if (
        this.data.is_entity_variable &&
        field.inputType == "ENTITY" &&
        field.field_assignable == "read_only" &&
        field?.entity_id &&
        this.entityDataMapping &&
        this.entityDataMapping[field.entity_id]
      ) {
        return this.entityDataMapping[field.entity_id];
      } else if (this.entityDataMapping[field.key]) {
        return this.entityDataMapping[field.key];
      } else if (
        this.entityDataMapping[field.key + "@relatedfill"] &&
        form?.["allRelationData"]?.[field.entity_id]
      ) {
        let { data } = this.entityDataMapping[field.key + "@relatedfill"],
          dataArray = [];
        (form?.["allRelationData"]?.[field.entity_id] || []).forEach(e => {
          if (data[e]) {
            dataArray.push(data[e]);
          }
        });
        return {
          ...this.entityDataMapping[field.key + "@relatedfill"],
          ...{
            data: dataArray
          }
        };
      }
      return null;
    },
    getParentFormData(column) {
      if (column.input_type == "ENTITY") {
        return this.form;
      }
      return null;
    },
    isGetView(field) {
      if (
        field.input_type == "ENTITY_VARIABLE" &&
        field?.field_assignable == "read_only" &&
        // form[field.key + "@autofill"] &&
        !this.data.is_entity_variable
      ) {
        return true;
      } else if (this.data.child_table_type == "view") {
        return true;
      }
      return this.isView;
    },
    async fetchEntityFieldData() {
      this.loading = true;
      this.fetchingEntityData = true;
      this.loadingText = "Fetching " + this.auto_fill_field.label;
      this.rowsData = [];
      this.form[this.data.key] = [];
      let fields = this.getTableMappedFields();
      let entityFields = [],
        relatedEntityFields = [];
      fields.forEach(e => {
        if (e.inputType == "ENTITY") {
          if (e.filters && e.filters.length) {
            let rowFilter = e.filters.find(
              f =>
                f.data_source == "self_field" &&
                f.value_field &&
                f.value_field.includes("@") &&
                f.data_type == "RELATION" &&
                f.relation_entity_id == this.auto_fill_field?.entity_id
            );
            if (rowFilter) {
              relatedEntityFields.push({
                ...e,
                ...{
                  relatedFields: true
                }
              });
            } else if (this.auto_fill_field.key == e.key) {
              entityFields.push(e);
            }
          } else {
            entityFields.push(e);
          }
        }
      });
      if (entityFields.length) {
        this.autoFillEntityData = null;
        await Promise.all(
          [...entityFields, ...relatedEntityFields].map(async entity => {
            if (
              this.auto_fill_field.key !== entity.key &&
              // !entity.relatedFields &&
              (this.entityDataMapping[entity.key] ||
                this.entityDataMapping[entity.key + "@relatedfill"])
            ) {
              return;
            }
            let filters = this.mapFilters(entity.filters);
            if (entity?.relatedFields) {
              filters = filters.filter(
                f =>
                  !(
                    f.data_source == "self_field" &&
                    f.value_field &&
                    f.value_field.includes("@") &&
                    f.data_type == "RELATION" &&
                    f.relation_entity_id == this.auto_fill_field?.entity_id
                  )
              );
            }
            let params = {
              entity_id: entity.entity_id,
              template_fields_data: [],
              filters: this.mapDateFilters(
                this.removeOptionalFilters(filters || [], false)
              ),
              relationship: {},
              limit: 500,
              page: 1
            };
            if (
              this.auto_fill_field.key == entity.key &&
              relatedEntityFields.length
            ) {
              params.relational_entities = relatedEntityFields.flatMap(
                e => e.entity_id
              );
            }
            const response = await postAPICall(
              "POST",
              "/entities-data/entity/data",
              params
            );
            if (this.auto_fill_field.key == entity.key) {
              this.autoFillEntityData = response;
              if (
                this.autoFillEntityData &&
                this.autoFillEntityData?.data.length
              ) {
                this.autoFillEntityData = {
                  ...this.autoFillEntityData,
                  ...{ update: true }
                };
              }
              if (this.autoFillEntityData?.data) {
                let dataOptions = [],
                  primaryField = this.autoFillEntityData.selectedEntity
                    .primaryFields[0];
                if (this.autoFillEntityData.data.length) {
                  this.autoFillEntityData = {
                    ...this.autoFillEntityData,
                    ...{ update: true }
                  };
                }
                let firstRowFormData = {};
                const entityVariablesExisted = this.entityVariableTableColumns;
                if (entityVariablesExisted && entityVariablesExisted.length) {
                  firstRowFormData = await this.getFirstRowFormData(
                    entityVariablesExisted
                  );
                }
                this.rowsData = [];
                this.$set(this.form, this.data.key, []);
                this.autoFillEntityData.data.forEach(data => {
                  if (data?.entityData) {
                    let form = {
                      [this.auto_fill_field.key]: data._id,
                      [this.auto_fill_field.key + "/name"]: this.getPrimaryName(
                        primaryField,
                        data
                      ),
                      [this.auto_fill_field.key + "@autofill"]: true,
                      ["keyIndex"]: (Math.random() + 1)
                        .toString(36)
                        .substring(7),
                      ...firstRowFormData
                    };
                    if (data?.relation_data) {
                      form["allRelationData"] = data.relation_data;
                    }
                    fields.forEach(field => {
                      if (
                        field.relationship_key == this.auto_fill_field.key &&
                        (field.field_assignable == "read_only" ||
                          field.inputType == "ENTITY" ||
                          !form[field.key])
                      ) {
                        if (
                          field.global_variable_entity_field &&
                          field.global_variable_entity_field.includes("~")
                        ) {
                          // we need to skip assign data part we if the field is relationship field
                          // because we are setting entity data. but entity data don't have relation data by default. it makes value as null.
                          return;
                        }
                        form[field.key + "@autofill"] = true;
                        if (field.global_variable_entity_field) {
                          let templateId = "";
                          let key = "";
                          let value = "";
                          let valueName = "";
                          if (
                            field.global_variable_entity_field.includes("#")
                          ) {
                            templateId = field.global_variable_entity_field.split(
                              "#"
                            )[0];
                            key = field.global_variable_entity_field.split(
                              "#"
                            )[1];
                          } else {
                            templateId = field.global_variable_entity_field;
                          }
                          if (parent?.allow_multiple) {
                            if (data.length) {
                              let dataValues = [],
                                result = 0,
                                min;
                              let existedIds = (form &&
                              form[field.key] &&
                              form[field.key].length
                                ? form[field.key]
                                : []
                              ).flatMap(dt => dt.parentDataId);
                              (data || []).forEach(e => {
                                if (
                                  e?.entityData?.[templateId]?.[key] &&
                                  existedIds.indexOf(e._id) == -1
                                ) {
                                  if (field.inputType == "DATA_TABLE") {
                                    if (e.entityData[templateId][key].length) {
                                      e?.entityData[templateId][key].forEach(
                                        dt => {
                                          dataValues.push({
                                            ...dt,
                                            ...{
                                              parentDataId: e._id,
                                              parentDataName: e.parentDataName
                                            }
                                          });
                                        }
                                      );
                                    }
                                  } else if (
                                    this.isNumber(e.entityData[templateId][key])
                                  ) {
                                    let v = e.entityData[templateId][key];
                                    if (
                                      field.variable_action == "SUM" ||
                                      field.variable_action == "AVERAGE"
                                    ) {
                                      result = result + parseInt(v);
                                    } else if (
                                      field.variable_action == "HIGHEST" &&
                                      v > result
                                    ) {
                                      result = parseInt(v);
                                    } else if (
                                      field.variable_action == "LOWEST"
                                    ) {
                                      if (v < min || min == undefined) {
                                        min = parseInt(v);
                                      }
                                    }
                                  }
                                }
                              });
                              if (existedIds.length) {
                                dataValues = [
                                  ...dataValues,
                                  ...(form[field.key] || [])
                                ];
                              }
                              if (field.inputType == "DATA_TABLE") {
                                this.$set(form, field.key, dataValues);
                              } else {
                                if (field.variable_action == "AVERAGE") {
                                  let avg = result / data.length;
                                  this.$set(form, field.key, avg);
                                } else if (field.variable_action == "LOWEST") {
                                  this.$set(form, field.key, min);
                                } else {
                                  this.$set(form, field.key, result);
                                }
                              }
                            }
                          } else {
                            if (
                              data.entityData &&
                              data.entityData[templateId] &&
                              data.entityData[templateId][key]
                            ) {
                              value = data.entityData[templateId][key];

                              if (field.inputType == "ENTITY") {
                                valueName =
                                  data.entityData[templateId][key + "/name"];
                                if (typeof value == "object" && value.length) {
                                  this.$set(
                                    form,
                                    field.key + "/default",
                                    value
                                  );
                                } else {
                                  this.$set(form, field.key + "/default", [
                                    value
                                  ]);
                                }
                              }
                            }

                            if (form[field.key]) {
                              if (data.entityData[templateId][key + "_code"]) {
                                form[field.key + "_code"] =
                                  data.entityData[templateId][key + "_code"];
                              }
                              form[field.key] = value;
                              if (valueName) {
                                form[field.key + "/name"] = valueName;
                              }
                            } else {
                              this.$set(form, field.key, value);
                              if (valueName) {
                                this.$set(form, field.key + "/name", valueName);
                              }
                            }

                            if (field.global_variable_pay_field) {
                              //logic for Payment Variable fields
                              this.$set(
                                form,
                                field.global_variable_entity_select_type_field_key +
                                  "_" +
                                  field.global_variable_pay_field,
                                data.entityData[templateId][
                                  field.global_variable_entity_select_type_field_key +
                                    "_" +
                                    field.global_variable_pay_field
                                ]
                              );
                            }
                            if (
                              field.inputType == "PAY_BUTTON" ||
                              field.input_type == "PAY_BUTTON"
                            ) {
                              // pay button data set block

                              form[field.key + "_info"] =
                                data.entityData[templateId][
                                  field.global_variable_entity_select_type_field_key +
                                    "_info"
                                ];
                              form[field.key + "_history"] =
                                data.entityData[templateId][
                                  field.global_variable_entity_select_type_field_key +
                                    "_history"
                                ];
                            }
                          }
                        }
                      }
                    });
                    form = this.mapDefaultValues(
                      this.data.data_table_columns,
                      form,
                      false,
                      this.form
                    );
                    this.rowsData.push({
                      fields,
                      form
                    });
                    dataOptions.push(form);
                  }
                });
                this.$set(this.form, this.data.key, dataOptions);
                setTimeout(() => {
                  this.$emit("onNewRowAdded", {});
                }, 300);
              }
            } else {
              if (entity?.relatedFields) {
                let entityDataObj = {};
                (response.data || []).forEach(d => {
                  entityDataObj[d._id] = d;
                });
                this.entityDataMapping[entity.key + "@relatedfill"] = {
                  ...response,
                  ...{
                    update: true,
                    data: entityDataObj
                  }
                };
              } else {
                this.entityDataMapping[entity.key] = {
                  ...response,
                  ...{
                    update: true
                  }
                };
              }
            }
          })
        );
      }
      this.loading = false;
      this.fetchingEntityData = false;
    },
    async autoFillFilteredData() {
      this.rowsData = [];
      this.form[this.data.key] = [];
      let filters = [];
      // let filters = JSON.parse(JSON.stringify([...this.data.filters]));
      filters = (this.data.filters || []).map(e => {
        if (e.field == "self#related_to/parent") {
          e.value = this.data.parent_entityDataId;
          e.relation_entity_id = this.data.parent_entityId;
        } else if (this.form[e.value_field]) {
          e.value = this.form[e.value_field];
        } else if (!e.value) {
          e.value = "";
        }
        return e;
      });
      if (
        (this.entityDataId || !filters.length) &&
        this.data.data_table_field_auto_fill &&
        this.data.selected_auto_fill_entity !== "" &&
        this.data.selected_auto_fill_entity == this.data.parent_entityId
      ) {
        filters.push({
          data_type: "RELATION",
          field: "self#related_to/parent",
          operator: "=",
          query_type: "OR",
          value: this.data.parent_entityDataId,
          relation_entity_id: this.data.parent_entityId
        });
      }
      let atLeastOneFilterValueExisted = filters.find(e => e.value !== "");
      if (atLeastOneFilterValueExisted !== undefined || !filters.length) {
        this.loading = true;
        let params = {
          entity_id: this.data.entity_id,
          filters: filters,
          limit: 500,
          page: 1
        };
        const response = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        if (response?.data) {
          let rows = [];
          this.form[this.data.key] = [];
          response.data.map(eData => {
            let form = {};
            form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
            form["_id"] = eData._id;
            this.data.data_table_columns.map(f => {
              if (
                eData?.entityData?.[f.key.split("@")[0]]?.[f.key.split("@")[1]]
              ) {
                form[f.key] =
                  eData.entityData[f.key.split("@")[0]][f.key.split("@")[1]];
                if (
                  Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                    `${f.key.split("@")[1]}/name`
                  )
                ) {
                  form[`${f.key}/name`] =
                    eData.entityData[f.key.split("@")[0]][
                      `${f.key.split("@")[1]}/name`
                    ];
                }
                if (
                  Object.keys(eData.entityData[f.key.split("@")[0]]).includes(
                    `${f.key.split("@")[1]}_code`
                  )
                ) {
                  form[`${f.key}_code`] =
                    eData.entityData[f.key.split("@")[0]][
                      `${f.key.split("@")[1]}_code`
                    ];
                }
              }
              if (f.input_type === "PAY_BUTTON") {
                let field = f.key.split("@");
                form[`${f.key}`] = eData.entityData[field[0]][field[1]];
                form[`${f.key}_history`] =
                  eData.entityData[field[0]][`${field[1]}_history`] || [];
                form[`${f.key}_info`] =
                  eData.entityData[field[0]][`${field[1]}_info`] || {};
                form[`${f.key}_cust_info`] =
                  eData.entityData[field[0]][`${field[1]}_cust_info`] || {};
                form[`${field[0]}@${f.validations.entityVariable.key}`] =
                  eData.entityData[field[0]][f.validations.entityVariable.key];
              }
            });
            form = this.mapDefaultValues(
              this.data.data_table_columns,
              form,
              false,
              this.form
            );
            let row = {
              fields: this.data.data_table_columns,
              form: form
            };
            this.rowsData.push(row);
            rows.push(row);
          });
          this.$set(this.form, this.data.key, rows);
          this.loading = false;
        }
      }
    },
    async autoFillPreferredFields() {
      this.loading = true;
      this.loadingText = "Auto Filling preferred fields...";
      let fields = this.getTableMappedFields();
      this.rowsData = [];
      for (const e of this.auto_fill_fields) {
        if (e.input_type === "DATE") {
          let toBeFilledData = [];
          if (e.data_table_field_auto_fill_type === "range") {
            if (this.form[e.selected_auto_fill_fields.range]) {
              toBeFilledData = await this.getAllBetweenData(
                {
                  from: this.form[e.selected_auto_fill_fields.range][0],
                  to: this.form[e.selected_auto_fill_fields.range][1]
                },
                "DATE",
                e.data_table_filters,
                e.selected_auto_fill_fields.frequency_step,
                e.selected_auto_fill_fields.frequency_type,
                e.data_table_filter_query
              );
            }
          } else if (e.data_table_field_auto_fill_type === "custom") {
            if (
              this.form[e.selected_auto_fill_fields.from] &&
              this.form[e.selected_auto_fill_fields.to] &&
              moment(this.form[e.selected_auto_fill_fields.from]) <
                moment(this.form[e.selected_auto_fill_fields.to])
            ) {
              toBeFilledData = await this.getAllBetweenData(
                {
                  from: moment(this.form[e.selected_auto_fill_fields.from]),
                  to: moment(this.form[e.selected_auto_fill_fields.to])
                },
                "DATE",
                e.data_table_filters,
                e.selected_auto_fill_fields.frequency_step,
                e.selected_auto_fill_fields.frequency_type,
                e.data_table_filter_query
              );
            }
          } else if (e.data_table_field_auto_fill_type === "frequency") {
            if (
              this.form[e.selected_auto_fill_fields.from] &&
              e.selected_auto_fill_fields.to &&
              e.selected_auto_fill_fields.type
            ) {
              let type =
                e.selected_auto_fill_fields.type !== "week"
                  ? e.selected_auto_fill_fields.type
                  : "days";
              let number =
                e.selected_auto_fill_fields.type !== "week"
                  ? e.selected_auto_fill_fields.to
                  : e.selected_auto_fill_fields.to * 7;
              let toDate = moment(
                this.form[e.selected_auto_fill_fields.from]
              ).add(number, type);
              toBeFilledData = await this.getAllBetweenData(
                {
                  from: this.form[e.selected_auto_fill_fields.from],
                  to: toDate
                },
                "DATE",
                e.data_table_filters,
                e.selected_auto_fill_fields.frequency_step,
                e.selected_auto_fill_fields.frequency_type,
                e.data_table_filter_query
              );
            }
          }
          let rows = [];
          toBeFilledData.map(d => {
            let form = {};
            form[e.key] = d;
            form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
            form = this.mapDefaultValues(
              this.data.data_table_columns,
              form,
              false,
              this.form
            );
            let row = {
              fields: fields,
              form: form
            };
            this.rowsData.push(row);
            rows.push(row);
          });
          this.$set(this.form, this.data.key, rows);
          setTimeout(() => {
            this.$emit("onNewRowAdded", {});
          }, 300);
        } else if (e.input_type === "WEEKDAYS" && !e.allow_multiple) {
          let autoFilledField = this.fieldsData.find(
            field => field.key == e.selected_auto_fill_fields.from
          );
          if (autoFilledField && autoFilledField.input_type == "WEEKDAYS") {
            if (this.form[e.selected_auto_fill_fields.from].length) {
              let rows = [];
              this.form[e.selected_auto_fill_fields.from].map(day => {
                let form = {};
                form[e.key] = day;
                form["keyIndex"] = (Math.random() + 1)
                  .toString(36)
                  .substring(7);
                form = this.mapDefaultValues(
                  this.data.data_table_columns,
                  form,
                  false,
                  this.form
                );
                let row = {
                  fields: fields,
                  form: form
                };
                this.rowsData.push(row);
                rows.push(row);
              });
              this.$set(this.form, this.data.key, rows);
              setTimeout(() => {
                this.$emit("onNewRowAdded", {});
              }, 300);
            }
          } else {
            this.rowsData.map((row, index) => {
              if (row.form[e.selected_auto_fill_fields.from]) {
                let day = moment(
                  row.form[e.selected_auto_fill_fields.from]
                ).day();
                switch (day) {
                  case 0:
                    this.rowsData[index].form[e.key] = "SUN";
                    break;
                  case 1:
                    this.rowsData[index].form[e.key] = "MON";
                    break;
                  case 2:
                    this.rowsData[index].form[e.key] = "TUE";
                    break;
                  case 3:
                    this.rowsData[index].form[e.key] = "WED";
                    break;
                  case 4:
                    this.rowsData[index].form[e.key] = "THU";
                    break;
                  case 5:
                    this.rowsData[index].form[e.key] = "FRI";
                    break;
                  case 6:
                    this.rowsData[index].form[e.key] = "SAT";
                    break;
                }
              }
            });
            this.$set(this.form, this.data.key, this.rowsData);
          }
        }
      }
      this.$emit("onDataAdded");
      this.loading = false;
    },
    async getAllBetweenData(
      range,
      type,
      filters = [],
      step = "days",
      number = 1,
      query = "AND"
    ) {
      let data = [];
      if (type == "DATE") {
        if (step == "week") {
          number = number * 7;
          step = "days";
        }
        for (
          let currentDate = moment(range.from);
          currentDate <= range.to;
          currentDate.add(step, number)
        ) {
          data.push(moment(currentDate));
        }
        let weekDays = {
          MON: 1,
          TUE: 2,
          WED: 3,
          THU: 4,
          FRI: 5,
          SAT: 6,
          SUN: 0
        };
        let weekDaysFieldData = [];
        let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (filters.length) {
          let toBeReturnedData = [];
          data.map(dt => {
            let allFiltersResponse = filters.map(filter => {
              if (this.form[filter.field] || filter.type == "ENTITY") {
                switch (filter.operator) {
                  case "EQUALS":
                    if (filter.type == "DATE") {
                      return (
                        moment(dt).format("DD-MM-YYYY") ==
                        moment(this.form[filter.field]).format("DD-MM-YYYY")
                      );
                    } else if (filter.type == "WEEKDAYS_SINGLE") {
                      return (
                        moment(dt).day() == weekDays[this.form[filter.field]]
                      );
                    }
                    break;
                  case "NOT_EQUALS":
                    if (filter.type == "DATE") {
                      return (
                        moment(dt).format("DD-MM-YYYY") !==
                        moment(this.form[filter.field]).format("DD-MM-YYYY")
                      );
                    } else if (filter.type == "WEEKDAYS_SINGLE") {
                      return (
                        moment(dt).day() !== weekDays[this.form[filter.field]]
                      );
                    }
                    break;
                  case "BETWEEN":
                    return (
                      moment(dt).format("DD-MM-YYYY") >=
                        moment(this.form[filter.field][0]).format(
                          "DD-MM-YYYY"
                        ) &&
                      moment(dt).format("DD-MM-YYYY") <=
                        moment(this.form[filter.field][1]).format("DD-MM-YYYY")
                    );
                  case "NOT_BETWEEN":
                    return (
                      moment(dt).format("DD-MM-YYYY") <
                        moment(this.form[filter.field][0]).format(
                          "DD-MM-YYYY"
                        ) ||
                      moment(dt).format("DD-MM-YYYY") >
                        moment(this.form[filter.field][1]).format("DD-MM-YYYY")
                    );
                  case "IN":
                    if (filter.type == "WEEKDAYS") {
                      weekDaysFieldData = this.form[filter.field].map(
                        f => weekDays[f]
                      );
                      return weekDaysFieldData.includes(moment(dt).day());
                    } else if (filter.type == "ENTITY") {
                      let selectedFieldDatas = [];
                      if (
                        this.entityDatas[filter.entity_id] &&
                        this.entityDatas[filter.entity_id].length
                      ) {
                        this.entityDatas[filter.entity_id].map(eData => {
                          if (
                            eData &&
                            eData.entityData &&
                            Object.keys(eData.entityData).includes(
                              filter.field.split("#")[0]
                            ) &&
                            eData.entityData[filter.field.split("#")[0]][
                              filter.field.split("#")[1]
                            ]
                          ) {
                            selectedFieldDatas.push(
                              moment(
                                eData.entityData[filter.field.split("#")[0]][
                                  filter.field.split("#")[1]
                                ]
                              )
                                .tz(currentTimezone.toString())
                                .format("DD-MM-YYYY")
                            );
                          }
                        });
                      }
                      return selectedFieldDatas.includes(
                        moment(dt).format("DD-MM-YYYY")
                      );
                    }
                    break;
                  case "NOT_IN":
                    if (filter.type == "WEEKDAYS") {
                      weekDaysFieldData = this.form[filter.field].map(
                        f => weekDays[f]
                      );
                      return !weekDaysFieldData.includes(moment(dt).day());
                    } else if (filter.type == "ENTITY") {
                      let selectedFieldDatas = [];
                      if (
                        this.entityDatas[filter.entity_id] &&
                        this.entityDatas[filter.entity_id].length
                      ) {
                        this.entityDatas[filter.entity_id].map(eData => {
                          if (
                            eData &&
                            eData.entityData &&
                            Object.keys(eData.entityData).includes(
                              filter.field.split("#")[0]
                            ) &&
                            eData.entityData[filter.field.split("#")[0]][
                              filter.field.split("#")[1]
                            ]
                          ) {
                            selectedFieldDatas.push(
                              moment(
                                eData.entityData[filter.field.split("#")[0]][
                                  filter.field.split("#")[1]
                                ]
                              )
                                .tz(currentTimezone.toString())
                                .format("DD-MM-YYYY")
                            );
                          }
                        });
                      }
                      return !selectedFieldDatas.includes(
                        moment(dt).format("DD-MM-YYYY")
                      );
                    }
                    break;
                }
              }
            });
            if (query == "AND" && !allFiltersResponse.includes(false)) {
              toBeReturnedData.push(dt);
            } else if (query == "OR" && allFiltersResponse.includes(true)) {
              toBeReturnedData.push(dt);
            }
          });
          return toBeReturnedData;
        } else {
          return data;
        }
      }
    },
    mapFilters(filters) {
      return (filters || []).map(e => {
        if (
          e &&
          e.data_source == "self_field" &&
          e.value_field &&
          !e.primary_filter
        ) {
          e.value = this.form[e.value_field];
          if (this.parentFormData && this.parentFormData[e.value_field]) {
            e.value = this.parentFormData[e.value_field];
          }
          e.data_type = "RELATION";
        } else if (
          e &&
          e.data_source == "from_date" &&
          e.data_type == "WEEKDAYS" &&
          e.value_field
        ) {
          let key = e.value_field.includes("#")
            ? e.value_field.split("#")[1]
            : e.value_field;
          if (this.form[key]) {
            let date = new Date(this.form[key]).getDay();
            e.value = [this.weekDays[date]];
          } else {
            e.value = [];
          }
        } else if (
          e.field == "UNIQUE" &&
          this.data?.parent_entityId &&
          this.data?.template_id
        ) {
          e.entity_id =
            this.data?.parent_entityId +
            "#" +
            this.data?.template_id +
            "#" +
            this.data.key;
          if (this.data?.parent_entityDataId) {
            e.parent_entityDataId = this.data.parent_entityDataId;
          }
          if (e.unique_field) {
            if (e.unique_field.includes("#")) {
              e.unique_value = this.form[e.unique_field.split("#")[1]];
            } else {
              e.unique_value = this.form[e.unique_field];
            }
          }
          if (e.limit_field && e.limit_field.includes("#")) {
            e.current_limit_field = parseInt(
              this.form[e.limit_field.split("#")[1]]
            );
          }
        } else if (e.data_source == "TEMPLATE" && e.value_field) {
          let v = this.form[e.value_field];
          if (this.parentFormData && this.parentFormData[e.value_field]) {
            v = this.parentFormData[e.value_field];
          }
          if (
            (e.operator == "in" || e.operator == "nin") &&
            typeof v != "object"
          ) {
            e.value = [v];
          } else if (e.data_type == "NUMBER") {
            e.value = parseInt(v || 0);
          } else {
            e.value = v;
          }
        }
        return e;
      });
    },
    checkIsDisabled(key, rowIndex) {
      if (this.checkAllFieldsDisabled) {
        return true;
      }
      return this.disabledFields.find(e => e == key + rowIndex) ? true : false;
    },
    checkhideFields(key, rowIndex) {
      return this.hideFields.find(e => e == key + "$" + rowIndex)
        ? true
        : false;
    },
    rulesEventEmitter(fields, rowIndex, keyIndex) {
      if (this.entityDataExists) {
        return;
      }
      let changedDataIndex = (this.form[this.data.key] || []).findIndex(
        e => e.keyIndex == keyIndex && !e.newlyAddedData
      );
      if (changedDataIndex > -1) {
        if (
          this.form[this.data.key][changedDataIndex] &&
          this.getRowsData[rowIndex].form
          // !this.deepEqual(
          //   this.form[this.data.key][changedDataIndex],
          //   this.getRowsData[rowIndex].form
          // )
        ) {
          this.applyRulesOnDataTableFields(fields, this.fieldRules, rowIndex);
        }
      }
    },
    splitRow(index) {
      this.addNewRow(index + 1);
    },
    checkEntityVariable(action) {
      if (this.data?.is_entity_variable) {
        if (action == "ADD") {
          if (this.data?.alow_data_table_add) {
            return false;
          }
        } else if (action == "EDIT") {
          if (this.data?.alow_data_table_edit) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    hiddenFields(fields) {
      let allowedFields = ["ENTITY", "FORMULA", "AGGREGATE_FUNCTION"];
      return fields.filter(data => {
        if (allowedFields.indexOf(data.inputType) === -1) {
          return false;
        }
        if (data.inputType == "ENTITY") {
          let childField = fields.find(
            e =>
              e.input_type == "ENTITY_VARIABLE" &&
              e.relationship_key == data.key
          );
          if (!childField) {
            return false;
          }
        }
        if (data?.properties?.filed_content === "Hide") {
          return true;
        }
        if (
          this.data?.is_entity_variable &&
          data?.show_in_split &&
          !data.field_operation
        ) {
          return true;
        }
        return false;
      });
    },
    groupFieldsByOrder(fields, sort = {}) {
      let groupId, paymentId;
      if (sort.isHeader) {
        paymentId = sort.field.key;
        let temp = paymentId.split("@")[0];
        groupId = temp + "@" + sort.field.validations.childGroupField;
      } else {
        groupId = sort.groupId;
        paymentId = sort.paymentId;
      }

      let totalFields = this.removeHideFields(fields);
      let fieldsWithOut_G_P = totalFields.filter(
        field => field.key !== groupId && field.key !== paymentId
      );
      if (sort.considerGroupFieldsForHeader) {
        const groupFields = fields.find(field => field.key === groupId);
        const payFields = fields.find(field => field.key === paymentId);
        // Insert the specific fields at the desired positions
        let shiftedArray = [];
        if (groupFields) {
          // fieldsWithOut_G_P.unshift(payFields);
          shiftedArray.push(groupFields); // Add payField to the start
        }
        shiftedArray.push(...fieldsWithOut_G_P);
        if (payFields) {
          //  fieldsWithOut_G_P.unshift(groupFields); // Add billingFreqField to the start
          shiftedArray.push(payFields);
        }
        return shiftedArray;
      }
      return fieldsWithOut_G_P;
    },
    removeHideFields(fields) {
      let notHiddenFields = fields.filter(data => {
        if (data?.properties?.filed_content != "Hide") {
          if (
            this.data?.is_entity_variable &&
            data?.show_in_split &&
            !data.field_operation
          ) {
            return false;
          }
          return (this.hiddenColumns || []).find(
            e => e == this.data.key + "#" + data.key
          )
            ? false
            : true;
        }
      });
      if (
        this.data.data_table_field_auto_fill &&
        this.data.selected_auto_fill_entity == this.data.parent_entityId
      ) {
        notHiddenFields = notHiddenFields.filter(
          e =>
            !(
              e.inputType == "ENTITY" &&
              e.entity_id.toString() ==
                this.data.selected_auto_fill_entity.toString()
            )
        );
      }
      return notHiddenFields;
    },
    updateParentUI() {
      this.$emit("onNewRowAdded", {});
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "HTML_CONTENT") {
          // this.isList = true;
          this.data.content = this.getGlobalVariableById.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },

    async setDataToEntityVariables(data, parent, label, changed, index = null) {
      if (index != null && index > -1) {
        this.updateSingleRowEntityFieldsRows(
          data,
          parent,
          label,
          changed,
          index
        );
      } else {
        this.updateAllEntityFieldsRows(data, parent, label, changed);
      }
    },

    async unsetEntityVariables(parent, index = null) {
      if (index != null && index > -1) {
        if (!this.rowsData[index] || !this.rowsData[index].form) {
          return;
        }
        this.childFieldsData.forEach(field => {
          if (field.relationship_key == parent.key) {
            this.$set(this.rowsData[index].form, field.key, "");
          }
        });
      } else {
        this.childFieldsData.forEach(field => {
          if (field.relationship_key == parent.key) {
            this.rowsData.forEach((row, i) => {
              this.$set(this.rowsData[i].form, field.key, "");
            });
          }
        });
      }
    },

    updateAllEntityFieldsRows(data, parent, label, changed) {
      // let entityId = data && data._id ? data._id : null;
      if (label) {
        this.rowsData.forEach((row, index) => {
          this.$set(this.rowsData[index].form, parent.key + "/name", label);
        });
      }
      this.childFieldsData.forEach(field => {
        if (
          field &&
          parent &&
          field.relationship_key == parent.key &&
          // data?.entityData &&
          (field.field_assignable == "read_only" || changed)
        ) {
          if (
            (field.global_variable_entity_field &&
              field.global_variable_entity_field.includes("~")) ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }

            if (
              data &&
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];

              if (field.inputType == "ENTITY") {
                valueName = data.entityData[templateId][key + "/name"];
                this.rowsData.forEach((row, index) => {
                  this.$set(this.rowsData[index].form, field.key + "/default", [
                    value
                  ]);
                });
              }
            }

            this.rowsData.forEach((row, index) => {
              this.$set(this.rowsData[index].form, field.key, value);
            });

            if (valueName) {
              this.rowsData.forEach((row, index) => {
                this.$set(
                  this.rowsData[index].form,
                  field.key + "/name",
                  valueName
                );
              });
            }
          }
        }
      });

      // this.rowsData.forEach((row, index) => {
      //   if (!this.rowsData[index].form.parent_data) {
      //     this.rowsData[index].form.parent_data = {};
      //   }
      //   if (entityId) {
      //     this.rowsData[index].form.parent_data[entityId] = data;
      //   } else {
      //     this.rowsData[index].form.parent_data = {};
      //   }
      // });
    },

    updateSingleRowEntityFieldsRows(data, parent, label, changed, index) {
      if (!this.rowsData[index] || !this.rowsData[index].form) {
        return;
      }
      // let entityId = data && data._id ? data._id : null;
      if (label) {
        this.$set(this.rowsData[index].form, parent.key + "/name", label);
      }

      this.childFieldsData.forEach(field => {
        if (
          field.relationship_key == parent.key &&
          (field.field_assignable == "read_only" || changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min;
                (data || []).forEach(e => {
                  if (e?.entityData?.[templateId]?.[key]) {
                    if (field.inputType == "DATA_TABLE") {
                      if (e.entityData[templateId][key].length) {
                        e?.entityData[templateId][key].forEach(dt => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName
                            }
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (field.inputType == "DATA_TABLE") {
                  value = dataValues;
                  // this.$set(this.rowsData[index].form, field.key, dataValues);
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    // this.$set(this.rowsData[index].form, field.key, avg);
                    value = avg;
                  } else if (field.variable_action == "LOWEST") {
                    // this.$set(this.rowsData[index].form, field.key, min);
                    value = min;
                  } else {
                    // this.$set(this.rowsData[index].form, field.key, result);
                    value = result;
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                if (
                  data.entityData[templateId][key + "@autofill"] &&
                  this.rowsData[index]?.form
                ) {
                  value = this.rowsData[index].form[key];
                  valueName = this.rowsData[index].form[key + "/name"];
                } else {
                  value = data.entityData[templateId][key];

                  if (field.inputType == "ENTITY") {
                    valueName = data.entityData[templateId][key + "/name"];
                    this.$set(
                      this.rowsData[index].form,
                      field.key + "/default",
                      [value]
                    );
                  }
                }
              }
            }

            this.$set(this.rowsData[index].form, field.key, value);

            if (valueName) {
              this.$set(
                this.rowsData[index].form,
                field.key + "/name",
                valueName
              );
              // this.rowsData.forEach((row, index) => {
              //   this.$set(
              //     this.rowsData[index].form,
              //     field.key + "/name",
              //     valueName
              //   );
              // });
            }
          }
        }
      });

      // if (!this.rowsData[index].form.parent_data) {
      //   this.rowsData[index].form.parent_data = {};
      // }
      // if (entityId) {
      //   this.rowsData[index].form.parent_data[entityId] = data;
      // } else {
      //   this.rowsData[index].form.parent_data = {};
      // }
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    getTableMappedFields() {
      let fields = [...this.data.data_table_columns];

      return fields.map(e => {
        if (e.styles) {
          e.styles.labelStyle = "hide";
          e.styles.font = {};
        } else {
          e.styles = {
            labelStyle: "hide",
            font: {}
          };
        }
        if (e.properties) {
          e.properties = {
            ...e.properties,
            ...{
              hideLabel: true
            }
          };
        } else {
          e.properties = {
            hideLabel: true
          };
        }
        return e;
      });
    },
    async addNewRow(index) {
      this.entityTableDetails = this.getEntityTableDetails(
        this.data,
        this.form
      );
      if (index == -1 && this.entityTableDetails?.max <= this.rowsData.length) {
        this.$message({
          message: "Warning, Max " + this.data.label + " limit reached",
          type: "warning"
        });
        return;
      }
      let fields = this.getTableMappedFields();
      let scrollToIndex;
      let formData;

      if (index > -1) {
        this.rowsData.splice(index, 0, {
          form: {
            ...JSON.parse(JSON.stringify(this.rowsData[index - 1].form)),
            newlyAddedData: true
          },
          fields
        });
        scrollToIndex = index; // Scroll to the newly inserted row
      } else {
        formData = await this.prepareFormWithValues();
        this.rowsData.push({
          form: formData,
          fields
        });
        scrollToIndex = this.rowsData.length - 1; // Scroll to the last (newly added) row
      }
      setTimeout(() => {
        this.$emit("onNewRowAdded", {});

        // Scroll to the newly added row
        this.scrollToNewRow(scrollToIndex);

        // Highlight the newly added row with a sky-blue background
        // this.highlightNewRow(scrollToIndex);
      }, 300);

      this.new_added_row.push({
        form: formData,
        fields
      });
    },
    removeRow(index) {
      const Newkeys = this.new_added_row.map(e => e.form.keyIndex);

      if (
        this.data.allow_deletion ||
        Object.keys(this.rowsData[index].form).length === 1
      ) {
        this.rowsData.splice(index, 1);
        setTimeout(() => {
          this.$emit("onNewRowAdded", {});
        }, 300);
      } else {
        if (Newkeys.length > 0) {
          const newRows = this.rowsData.filter(e =>
            Newkeys.includes(e.form.keyIndex)
          );
          if (newRows.length > 0) {
            this.rowsData.splice(index, 1);
            setTimeout(() => {
              this.$emit("onNewRowAdded", {});
            }, 300);
          } else {
            this.$notify.error({
              title: "Warning",
              message: "You do not have permission to remove rows",
              type: "warning"
            });
          }
        } else {
          this.$notify.error({
            title: "Warning",
            message: "You do not have permission to remove rows",
            type: "warning"
          });
        }
      }
    },

    async prepareFormWithValues() {
      const entityVariablesExisted = this.entityVariableTableColumns;
      if (entityVariablesExisted.length) {
        let form = {};

        if (this.rowsData.length) {
          this.data.data_table_columns.filter(e => {
            if (
              e.input_type == "ENTITY_VARIABLE" &&
              e.selected_enity_field_data &&
              e.selected_enity_field_data.input_type != "ENTITY"
            ) {
              form[e.key] = this.rowsData[0].form[e.key];
            }
          });
        } else {
          let firstRowFormData = await this.getFirstRowFormData(
            entityVariablesExisted
          );
          form = this.mapDefaultValues(
            this.data.data_table_columns,
            firstRowFormData,
            false,
            this.form
          );
        }
        form["keyIndex"] = (Math.random() + 1).toString(36).substring(7);
        return form;
      }
      let form = this.mapDefaultValues(
        this.data.data_table_columns,
        {},
        false,
        this.form
      );
      return {
        ...form,
        ["keyIndex"]: (Math.random() + 1).toString(36).substring(7)
      };
    },
    readonly() {
      if (this.checkAllFieldsDisabled) {
        return true;
      }
      //If it's an entity varaible, have to check access

      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "DISABLED"
      ) {
        return true;
      }
    },
    isEntityFromOutside(field) {
      return this.fieldsData.find(
        e => e.inputType == "ENTITY" && e.key == field.relationship_key
      );
    },

    async getFirstRowFormData(entityVariablesExisted) {
      let entityConfigurations = [];

      let form = {};
      entityVariablesExisted.forEach(e => {
        if (this.form[e.outside_entity_field.key]) {
          //  fetch only value fields

          let record = {
            entity_id: e.outside_entity_field.entity_id,
            entity_data_id: this.form[e.outside_entity_field.key]
          };

          if (e.global_variable_entity_field_template_id) {
            record.template_ids = [e.global_variable_entity_field_template_id];
          } else {
            record.template_ids = [];
          }
          entityConfigurations.push(record);
        }
      });

      let finalUniqueData = [];

      for (let i = 0; i < entityConfigurations.length; i++) {
        let entityDataId = entityConfigurations[i].entity_data_id;

        let index = finalUniqueData.findIndex(
          e => e.entity_data_id == entityDataId
        );

        if (index == -1) {
          finalUniqueData.push(entityConfigurations[i]);
        } else {
          finalUniqueData[index].template_ids = [
            ...finalUniqueData[index].template_ids,
            ...entityConfigurations[i].template_ids
          ];
        }
      }

      if (finalUniqueData.length) {
        // we need to fetch records for first row from api

        let entitiesData = await this.entitiesDataForFirstRow(finalUniqueData);

        if (entitiesData.length) {
          entityVariablesExisted.forEach(field => {
            let fieldParentEntitData = entitiesData.find(entitiyData => {
              return (
                entitiyData._id == this.form[field.outside_entity_field.key]
              );
            });

            if (fieldParentEntitData) {
              if (
                fieldParentEntitData["entityData"] &&
                fieldParentEntitData["entityData"][
                  field.global_variable_entity_field_template_id
                ] &&
                fieldParentEntitData["entityData"][
                  field.global_variable_entity_field_template_id
                ][field.global_variable_entity_select_type_field_key]
              ) {
                form[field.key] =
                  fieldParentEntitData["entityData"][
                    field.global_variable_entity_field_template_id
                  ][field.global_variable_entity_select_type_field_key];
              }
            }
          });
        }
        return form;
      }
      return form;
    },

    async entitiesDataForFirstRow(finalUniqueData) {
      try {
        this.rowLoading = true;

        await this.$store.dispatch(
          "entitiesData/fetchEntitiesDataForDataTable",
          {
            fields: finalUniqueData
          }
        );

        return [...this.getEntitiesDataForDataTable];
      } catch (err) {
        console.error("entitiesDatafirstRow", err);
      } finally {
        this.rowLoading = false;
      }
    },
    async setNewRowElements() {
      // if (!this.entityDataList || !this.entityDataList.length) {
      //   this.rowsData = [];
      //   return;
      // }
      if (this.form[this.data.key] && this.form[this.data.key].length) {
        this.rowsData = [];
        let tableData = [];
        if (
          this.data.data_table_filters &&
          this.data.data_table_filters.length &&
          !this.readonly()
        ) {
          tableData = this.checkFilterConditions(
            this.form[this.data.key],
            this.data.data_table_filters,
            this.data.data_table_filter_query || "AND",
            this.data.data_table_columns
          );
        } else {
          tableData = this.form[this.data.key];
        }
        tableData.forEach(dt => {
          let fields = [
            ...this.data.data_table_columns.map(e => {
              if (e.styles) {
                e.styles.labelStyle = "hide";
              }

              if (e.properties) {
                e.properties.hideLabel = true;
              }

              return e;
            })
          ];
          let e = this.mapDefaultValues(fields, dt, false, this.form);
          let element = {
            form: e,
            fields
          };
          if (
            this.rowsData.findIndex(
              d =>
                d?.form?.keyIndex == e.keyIndex &&
                !d?.form?.newlyAddedData &&
                !e.newlyAddedData
            ) == -1
          ) {
            this.rowsData.push(element);
          }
        });
      } else {
        this.rowsData = [];
      }
      setTimeout(() => {
        this.$emit("onNewRowAdded", {});
      }, 300);
    },
    checkIsIndependentField(data) {
      if (!data?.filters || !data.filters.length) {
        return true;
      } else {
        let selfTableFilter = (data.filters || []).find(
          filter =>
            filter?.data_source == "self_field" &&
            filter?.data_type == "RELATION" &&
            filter.value_field &&
            this.fieldsData.findIndex(e => e.key == filter.value_field) === -1
        );
        if (selfTableFilter) {
          return false;
        }
        return true;
      }
    },
    checkFieldIsReadOnly(data) {
      if (
        (data.field_assignable === "read_only" && data.is_entity_variable) ||
        (data &&
          data.properties &&
          data.properties.filed_content === "DISABLED") ||
        (data &&
          data.properties &&
          data.properties.filed_content === "READ ONLY")
      ) {
        return true;
      }
      return false;
    },
    async fetchEntityFieldsData() {
      this.dataRefresh = true;
      await Promise.all(
        this.data.data_table_columns.map(async data => {
          if (
            data.input_type == "ENTITY" &&
            (this.checkFieldIsReadOnly(data) ||
              this.checkIsIndependentField(data))
          ) {
            let filters = [];
            if (
              this.checkIsIndependentField(data) &&
              data.filters &&
              !this.checkFieldIsReadOnly(data)
            ) {
              filters = this.mapFilters(data.filters);
            }
            let params = {
              entity_id: data.entity_id,
              template_fields_data: [],
              filters: filters,
              relationship: null,
              limit: 500,
              page: 1
            };
            const response = await postAPICall(
              "POST",
              "/entities-data/entity/data",
              params
            );
            if (response.data) {
              this.entityDataMapping[data.entity_id] = {
                ...response,
                ...{ update: true }
              };
            }
          }
          return data;
        })
      );
      this.dataRefresh = false;
    }
  },
  watch: {
    refreshEntityFields: {
      async handler(data) {
        if (data) {
          this.autoFillFilteredData();
        }
      },
      immediate: true
    },
    suggestedData: {
      async handler(data) {
        if (data[this.data.key]) {
          this.form[this.data.key] = data[this.data.key];
        }
      },
      deep: true
    },
    rowsData: {
      async handler(data) {
        if (
          // !this.data.is_entity_variable &&
          !this.checkDatatableDatasEqual(
            (data[this.data.key] || []).map(e => JSON.parse(JSON.stringify(e))),
            this.rowsData.map(e => JSON.parse(JSON.stringify(e.form)))
          )
        ) {
          this.$emit("updateTableData", {
            key: this.data.key,
            data: data.map(e => JSON.parse(JSON.stringify(e.form)))
          });
        }
      },
      deep: true
    },
    form: {
      async handler(data) {
        let currentDefaultData = {};
        this.data.data_table_columns.map((fd) => {
          if (
            fd.default_mapped_field &&
            this.data.data_table_columns.find(
              (f) => f.key == fd.default_mapped_field
            ) == undefined
          ) {
            currentDefaultData[fd.default_mapped_field] =
              data[fd.default_mapped_field];
          }
        });
        if (
          !this.savingData &&
          // this.data.is_entity_variable &&
          (!this.checkDatatableDatasEqual(
            (data[this.data.key] || []).map((e) =>
              JSON.parse(JSON.stringify(e))
            ),
            this.rowsData.map((e) => JSON.parse(JSON.stringify(e.form)))
          ) ||
            JSON.stringify(this.previousDefaultData) !==
              JSON.stringify(currentDefaultData))
        ) {
          this.setNewRowElements();
          this.setDataToEntityVariables(
            this.entityDataList,
            this.parentField,
            this.form[this.data.relationship_key + "/name"],
            true
          );
          this.previousDefaultData = JSON.parse(JSON.stringify(currentDefaultData));
        }
        let currentData = {};
        this.filters.map((e) => {
          if (e.value_field) {
            currentData[e.value_field] = data[e.value_field];
          }
        });
        if (
          JSON.stringify(this.previousFormData) !==
            JSON.stringify(currentData) &&
          this.auto_fill_field
        ) {
          this.previousFormData = { ...currentData };
          this.fetchEntityFieldData();
        }
        // this.rowsData = data[this.data.key].map(dt => {
        //     return {
        //         fields: this.data.data_table_columns,
        //         form: this.mapDefaultValues(this.data.data_table_columns, dt, false, data)
        //     }
        // }
        // )
        // this.$set(this.form, this.data.key, this.rowsData)
        if (
          !this.data.child_table_auto_fill &&
          this.data.child_table_type == "interactive"
        ) {
          this.auto_fill_fields = this.data.data_table_columns.filter(
            (e) => e.data_table_field_auto_fill
          );
          let currentAutoFillData = {};
          this.auto_fill_fields.map((e) => {
            currentAutoFillData[e.selected_auto_fill_fields.from] =
              data[e.selected_auto_fill_fields.from];
            currentAutoFillData[e.selected_auto_fill_fields.to] =
              data[e.selected_auto_fill_fields.to];
            currentAutoFillData[e.selected_auto_fill_fields.range] =
              data[e.selected_auto_fill_fields.range];
            if (e.data_table_filters && e.data_table_filters.length) {
              e.data_table_filters.map((filter) => {
                currentAutoFillData[filter.field] = data[filter.field];
              });
            }
          });
          this.data.data_table_columns.map((e) => {
            if (e && e.default_mapped_field) {
              currentAutoFillData[e.default_mapped_field] =
                data[e.default_mapped_field];
            }
          });
          if (
            JSON.stringify(currentAutoFillData) !==
              JSON.stringify(this.previousAutoFillData) &&
            !this.savingData
          ) {
            this.previousAutoFillData = {
              ...JSON.parse(JSON.stringify(currentAutoFillData)),
            };
            if (this.auto_fill_fields && this.auto_fill_fields.length) {
              await this.fetchEntityFieldsData();
              this.autoFillPreferredFields();
            } else if (this.auto_fill_field && !this.fetchingEntityData) {
              this.fetchEntityFieldData();
            }
          }
        } else if (
          this.data.child_table_auto_fill ||
          this.data.child_table_type == "view"
        ) {
          let currentAutoFillData = {};
          this.data.filters.map((fil) => {
            currentAutoFillData[fil.value_field] = data[fil.value_field];
          });
          if (
            JSON.stringify(currentAutoFillData) !==
            JSON.stringify(this.previousAutoFillData)
          ) {
            this.previousAutoFillData = {
              ...JSON.parse(JSON.stringify(currentAutoFillData)),
            };
            if (
              (this.auto_fill_fields && this.auto_fill_fields.length) ||
              (this.data.filters && this.data.filters.length)
            ) {
              this.autoFillFilteredData();
            } else if (this.auto_fill_field && !this.fetchingEntityData) {
              this.fetchEntityFieldData();
            }
          }
        }
      },
      deep: true
    }
    // entityDataList: {
    //   async handler() {
    //     this.setNewRowElements();
    //     this.setDataToEntityVariables(
    //       this.entityDataList,
    //       this.parentField,
    //       this.form[this.data.relationship_key + "/name"],
    //       true
    //     );
    //   },
    // },
  }
};
</script>

<style lang="scss">
.responsive-font {
  font-size: 1.2vw;
}
</style>

<style scoped>
.table-container {
  overflow-y: hidden;
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
  overflow-x: auto;
  box-shadow: 0 0px 10px 3px rgba(27, 72, 126, 0.1294117647);
  scrollbar-width: thin;
  /* Add a horizontal scrollbar if content overflows */
}

.table-container::v-deep .el-scrollbar__view {
  /* Your styles for el-scrollbar__view go here */
  border-radius: 10px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.table-container::v-deep .el-scrollbar__bar {
  height: 0px !important;
  position: relative;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 120ms ease-out;
  transition: opacity 120ms ease-out;
  /* Your styles for el-scrollbar__view and el-scrollbar__bar go here */
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

th {
  text-align: left;
  /* Center align text horizontally */
  padding: 8px;
  background-color: var(--primary-color);
  /* Default background color for table heading */
  white-space: nowrap;
  color: var(--primary-contrast-color);
  /* Prevent line breaks in table headings */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Add ellipsis (...) for long headings */
}

th.sticky {
  /* Make the specified th elements sticky */
  position: sticky;
  left: 0;
  background-color: #f2f2f2;
  /* You can adjust the background color as needed */
  z-index: 2;
  /* Ensure it appears above other content when scrolling */
}

td {
  border: 1px solid #dddddd;
  text-align: center;
  /* Center align text horizontally */
  padding: 8px;
  white-space: nowrap;
  /* Prevent line breaks in cell content */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Add ellipsis (...) for long content */
}

tr:hover:not(:first-child) {
  background-color: #f9f9f9;
  /* Lighter grey color on hover for non-heading rows */
}

.content-table {
  /* Set a fixed table layout */
  table-layout: fixed;
}

.newly-added-row {
  background-color: skyblue;
  /* You can adjust the background color as needed */
}

@-moz-document url-prefix() {
  .scrollbar .el-scrollbar__view {
    display: grid;
    /* This is how we can apply browser specific styles by targeting pseudo classess. */
  }
}

.table-container th.sticky {
  position: sticky;
  right: 0;
  background-color: var(--primary-color);
  z-index: 2;
  width: 2vw;
}

.table-container th.sticky .d-flex {
  flex-direction: column;
  align-items: baseline;
}

.table-container th.sticky .d-flex el-button {
  margin-top: 5px; /* Adjust the margin as needed */
}

.table-container th.sticky .d-flex el-button + el-button {
  margin-top: 5px; /* Additional margin between buttons if needed */
}

.el-button--primary {
  color: var(--lighter-primary-color);
  background-color: var(--primary-contrast-color);
  border-color: var(--primary-color);
}

.el-button--primary:focus,
.el-button--primary:hover {
  color: var(--lighter-primary-color);
  background-color: var(--primary-contrast-color);
  border-color: var(--primary-color);
}
</style>
